
.row > * {
  margin-bottom: 1rem;
}
.btn {
  &.btn-export {
    line-height: 1;
    width: 250px;
    height: 80px;
    font-size: 22px;
    color: white;

    &:disabled {
      background-color: #aaa;
    }
  }

  &.btn-export {
    background-color: #56b9cc;
  }

  span {
    color: white;
  }
}
